<template>
	<div>
		<CCard>
			<CCardBody>
				<CRow>
					<CCol col="12" class="py-3">
						<AppointmentForm
							is-admin
							:from-customer="fromCustomer"
							class="admin-create-appt"
							@submit-form="createNewAppt"
							@cancel-form="goToApptList"
						></AppointmentForm>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { ADMIN_CREATE_APPT } from "./../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("serviceAppointment")

export default {
	name: "AdminCreateAppt",

	components: {
		AppointmentForm: () => import("./../components/AppointmentForm.vue"),
	},

	props: {
		isAdmin: {
			type: Boolean,
			default: false,
		},
		fromCustomer: {
			type: [Boolean, String],
			default: false,
		},
	},

	data() {
		return {}
	},

	computed: {},

	watch: {},

	created() {},

	methods: {
		...mapActions({ ADMIN_CREATE_APPT }),

		createNewAppt(dataParams) {
			this.ADMIN_CREATE_APPT(dataParams)
		},

		goToApptList() {
			this.$router.push({ name: "AppointmentManagement" })
		},
	},
}
</script>

<style lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";
.admin-create-appt {
	.appt-form {
		&__heading {
			margin-bottom: 20px;

			h5 {
				align-self: center;
				margin-right: 15px;
			}
		}

		&__container {
			.form-group,
			.sub-form-group {
				gap: 1%;
				&__label {
					color: inherit;
					font-weight: 500;
					flex: 0 0 100%;
					max-width: 100%;

					@media (min-width: $xs) {
						flex: 0 0 20%;
						max-width: 20%;
					}
					@media (min-width: $ml) {
						flex: 0 0 12%;
						max-width: 12%;
					}
				}

				&__input {
					flex: 0 0 100%;
					max-width: 100%;
					@media (min-width: $xs) {
						flex: 0 0 79%;
						max-width: 79%;
					}
					@media (min-width: $ml) {
						flex: 0 0 35%;
						max-width: 35%;
					}
				}

				.mx-input {
					font-size: 14px !important;
				}
			}

			.form-group {
				&__input {
					@media (min-width: $ml) {
						flex: 0 0 42%;
						max-width: 42%;
					}
				}
				&__label {
					font-size: 14px !important;
				}
			}

			.sub-form-group {
				@media (min-width: $xs) {
					margin-left: 21%;
				}
				@media (min-width: $ml) {
					margin-left: 13%;
				}
				&__label {
					font-size: 14px !important;
				}
			}

			.service .grid {
				grid-template-columns: 1fr;
				gap: 15px;
			}

			input.form-control,
			.mx-input {
				height: 35px !important;
			}

			.multiselect {
				min-height: 32px;
			}

			.status,
			.find-customer,
			.hiv-result {
				/deep/ .multiselect {
					&__select {
						height: 35px;
					}
					&__tags {
						padding-top: 7px;
						height: 35px;
						min-height: 35px;
					}
				}
			}

			.contact {
				height: 35px !important;

				.multiselect {
					width: 95%;
					min-height: 29px !important;
					height: 29px !important;

					&__tags {
						height: 29px !important;
						min-height: 29px !important;
					}
				}
				.option__desc {
					top: 0px;
				}
				.form-control {
					height: 33px !important;
				}
			}
		}

		&__action {
			display: flex;
			justify-content: space-between;

			@media (min-width: $xxs) {
				justify-content: center;

				.btn + .btn {
					margin-left: 40px;
				}
			}
		}
	}
}
</style>
